<template>
    <section>
        <bread-crumb :navigationArray="navigationLink"/>
        <sctucture-chamber/>
    </section>
</template>
<script>
import StructureChamber from '@/components/StructureChamber'
import BreadCrumb from '@/components/BreadCrumb'
export default {
  name: 'StructurePublicChamber',
  computed: {
    navigationLink () {
      return [
        {
          title: this.$store.getters.GET_LANG ? 'Главная' : 'Home',
          path: '/'
        },
        {
          title: this.$store.getters.GET_LANG ? 'О палате' : 'About the ward',
          path: '/'
        },
        {
          title: this.$store.getters.GET_LANG ? 'Структура ОП РФ' : 'CC RF structure'
        }
      ]
    }
  },
  mounted () {
    document.title = 'Структура Общественной палаты'
  },
  components: {
    'sctucture-chamber': StructureChamber,
    'bread-crumb': BreadCrumb
  }
}
</script>
<style lang="scss" scoped>

</style>
