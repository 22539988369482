<template>
  <div class="two-rows-wrapper">
        <main>
            <h5>{{ $store.getters.GET_LANG ? 'Структура Общественной палаты' : 'The structure of the public chamber' }}</h5>
            <div class="select-block">
                <div class="structure_select" @click="clickSelect">
                    <p class="structure_select-param" :class="{'icon_select': !!showSelect}">{{titleActive}}</p>
                </div>
                <transition name="fade">
                    <div class="display_select" v-show="!!showSelect">
                        <p :class="{'select-color': options.id === activeElement}" @click="elementClick(options)"
                        v-for="options of $store.getters.getConvocation" :key="options.id">
                            {{ $store.getters.GET_LANG ? options.name : options.nameEng }}
                        </p>
                    </div>
                </transition>
                <a href="https://old.oprf.ru/about/structure/" target="_blank" class="lists">
                    {{ $store.getters.GET_LANG ? 'Составы до 2020 года' : 'Lineups until 2020' }}
                </a>
            </div>
            <div class="block-structure" v-for="item of listNav" :key="'id_'+item.id">
              <template v-if="item.units && item.units.length">
                 <p :id="item.id" class="title_structure">{{item.title}}</p>
                <ul>
                    <li v-for="(index, i) of item.units" :key="i">
                        <router-link :to="`/structure_list/${index.id}`">{{index.name}}</router-link>
                    </li>
                </ul>
              </template>
            </div>
        </main>
          <aside class="two-rows-navigation">
            <h2>{{ $store.getters.GET_LANG ? 'Навигация по странице' : 'Page navigation' }}</h2>
            <nav>
              <a :href="'#'+item.id" v-for="item of listNav" :key="item.id">{{item.title}}</a>
            </nav>
          </aside>
    </div>
</template>
<script>
// import Uniq from '@/methods/uniq'

export default {
  name: 'StructureChamber',
  data () {
    return {
      titleActive: this.$store.getters.GET_LANG ? 'Выберите состав' : 'Select composition',
      activeElement: 1,
      showSelect: false,
      listNav: []
    }
  },
  async mounted () {
    await this.$store.dispatch('setConvocation')
    await this.getContent()
  },
  methods: {
    clickSelect () {
      this.showSelect = !this.showSelect
    },
    elementClick (event) {
      this.titleActive = this.$store.getters.GET_LANG ? event.name : event.nameEng
      this.activeElement = event.id
      this.showSelect = !this.showSelect
      this.getContent(event.id)
    },
    async getContent (id) {
      let res = await fetch(`${this.$store.getters.getUrlApi}api/structure?convocation=${id || this.$store.getters.getConvocation[1].id}`, {
        headers: {
          Localization: this.$store.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
      res = await res.json()
      console.log(await res)
      this.listNav = await res.data
    }
  },
  watch: {
    '$store.getters.GET_LANG' () {
      this.getContent()
    }
  }
}
</script>
<style lang="scss" scoped>

h5{
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;
}
.block-structure{ margin-top: 3.125rem; }
.block-structure:nth-of-type(2){ margin: 0;}

.title_structure{
    width: 100%;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: #1F3245;
    opacity: 0.8;
    padding-bottom: 0.5rem;
}

ul > li{
    list-style-type: disc;
    color: #246CB7;
    margin:  0.75rem 0 0 1rem;
}
a{
    font-size: 0.875rem;
    line-height: 1.38rem;
    color: #246CB7;
    margin-top: 0.75rem;
}
.select-block{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 2rem 0;
    position: relative;
}
.structure_select{
    width: 19.38rem;
    background: #EEF4FA;
    border-radius: .188rem;
    cursor: pointer;
}
.link-block{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.structure_select-param {
    font-size: .75rem;
    line-height: 1.125rem;
    color: #003E78;
    opacity: 0.6;
    padding: .5rem .75rem .56rem .75rem;
    background: none;
    background-size: 0;
    transition: background-size .35s ease;
}
.structure_select-button{
    width: 1.25rem;
    height: 1.25rem;
    background: url('../assets/icons/link.svg') center center no-repeat;
    background-size: cover;
    cursor: pointer;
    margin: 0;
}
.display_select{
    display: block;
    position: absolute;
    top: 3.5rem;
    z-index: 100;
    width: 19.375rem;
    background: #FFFFFF;
    border-radius: .188rem;
    padding: 1.125rem .88rem;
    box-shadow: 0 2.063rem 5.5rem rgba(37, 70, 103, 0.15);
    transition: all .0s;

    >p{
        font-size: .875rem;
        color: #003E78;
        padding: 0.75rem 0.75rem;
        cursor: pointer;
    }
}
.display_select:before{
    content: '';
    position: absolute;
    top: -0.375rem;
    left: 1.875rem;
    z-index: 99;
    width: .854rem;
    height: .854rem;
    background: #FFFFFF;
    box-shadow: 0 2.063rem 5.5rem rgba(37, 70, 103, 0.15);
    transform: rotate(45deg);
}
.select-color{
    background: rgba(36, 108, 183, 0.1);
    border-radius: .19rem;
}
.icon_select{
    background: url('../assets/icons/arrow.svg') 98% center no-repeat;
    transition: background-size .35s ease;
}
.fade-enter-active, .component-fade-leave-active {
  transition: all .35s;
}
.fade-enter, .component-fade-leave-to{
  opacity: 0;
}

.lists {
  margin: 0 0 0 1.375em;
  display: flex;
  align-items: center;

  font-size: .75rem;
  line-height: 1.125rem;
  color: #003E78;
  opacity: 0.6;
}

.lists::after {
  content: '';
  margin-left: 0.75rem;
  width: 0.7rem;
  height: 0.7rem;
  background-image: url('../assets/icons/icon_link.svg');
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 768px){
  .structure-main{
    width: auto;
    margin: 0 .75rem;
    & .white-container:nth-child(1){
        width: 100%;
        margin: 0;
        padding: 2.23rem 1.25rem;
    }
    & .white-container:nth-child(2){ display: none; }
  }
  .select-block{
    margin: 1.12rem 0 1.36rem 0;
  }
  .structure_select-button{
    margin-left: .38rem;
  }
  .display_select{
    z-index: 2;
  }
}
@media screen and (max-width: 420px){
  .structure-main{
    width: 100%;
    margin: 0;
  }
  .select-block{
    flex-direction: column;
  }
  .structure_select{
    width: 100%;
  }
  .structure-main .white-container:nth-child(1){
    padding: 1.38rem 1rem;
  }
  .select-block{
    margin: .88rem 0 .82rem 0;
  }
  ul > li {
    margin: 0.75rem 0 0 1rem;
    font-size: .64rem;
  }
  .block-structure{
    margin-top: 2rem;
  }
  .structure_select-button{
    margin-top: 0.45rem;
  }
  .display_select{
    top: 2.8rem;
    padding: .81rem 0.6rem;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .display_select {
    left: 0 !important;
  }
}

</style>
